import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[libNumericAndComaOnly]',
})
export class NumericAndComaOnlyDirective {
  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    if (event.key && /[^\d,]/.test(event.key)) {
      event.preventDefault();
    }
  }
}
