import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  OPTIONS_1234,
  OPTIONS_ABC,
  OPTIONS_A_RETENIR_SANS_SUITE,
  OPTIONS_FAV_DEFAV,
  OPTIONS_FAV_DEFAV_REO,
  OPTIONS_FAV_DEFAV_RES_AJOURNEE,
} from './projet-notation-creation.constants';
import { EnumNotationTypeNote } from '../../shared/enums/enumNotationTypeNote';

@Component({
  selector: 'lib-notation-avis-item',
  templateUrl: './notation-avis-item.component.html',
  styleUrls: ['./notation-avis-item.component.scss'],
})
export class NotationAvisItemComponent {
  readonly OPTIONS_FAV_DEFAV = OPTIONS_FAV_DEFAV;
  readonly OPTIONS_FAV_DEFAV_REO = OPTIONS_FAV_DEFAV_REO;
  readonly OPTIONS_A_RETENIR_SANS_SUITE = OPTIONS_A_RETENIR_SANS_SUITE;
  readonly OPTIONS_1234 = OPTIONS_1234;
  readonly OPTIONS_ABC = OPTIONS_ABC;
  readonly OPTIONS_FAV_DEFAV_RES_AJOURNEE = OPTIONS_FAV_DEFAV_RES_AJOURNEE;

  @Input() typeNotation: EnumNotationTypeNote;
  @Input() notationForm: UntypedFormGroup;
  @Input() isMandatory: boolean;

  onkeypress($event: any): boolean | void {
    if ($event.key) {
      if (!/[0-9,]/.test($event.key)) {
        return false;
      }
    }
  }

  protected readonly EnumNotationTypeNote = EnumNotationTypeNote;
}
