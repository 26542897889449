<div
  id="input-avis"
  class="form-step__avis ds-input-group"
  [class.ds-input-group--error]="!notationForm.controls['avis'].valid && notationForm.controls['avis'].touched"
>
  <label for="creation_notation_avis" class="ds-input-group__label fw-bold avis"
    >Avis<span class="ds-input-group__label-asterisk"> *</span></label
  >

  <div id="creation_notation_avis" class="select-container" *ngIf="typeNotation === EnumNotationTypeNote.NOTATION_FAVORABLE_DEFAVORABLE">
    <lib-radio-buttons [isMandatory]="isMandatory" [group]="notationForm" [options]="OPTIONS_FAV_DEFAV" controlName="avis">
    </lib-radio-buttons>
  </div>

  <div id="creation_notation_avis" class="select-container" *ngIf="typeNotation === EnumNotationTypeNote.NOTATION_FAVORABLE_DEFAVORABLE_REORIENTATION">
    <lib-radio-buttons [isMandatory]="isMandatory" [group]="notationForm" [options]="OPTIONS_FAV_DEFAV_REO" controlName="avis">
    </lib-radio-buttons>
  </div>

  <div id="creation_notation_avis" class="select-container" *ngIf="typeNotation === EnumNotationTypeNote.NOTATION_A_RETENIR_SANS_SUITE">
    <lib-radio-buttons [isMandatory]="isMandatory" [group]="notationForm" [options]="OPTIONS_A_RETENIR_SANS_SUITE" controlName="avis">
    </lib-radio-buttons>
  </div>

  <div id="creation_notation_avis" class="select-container" *ngIf="typeNotation === EnumNotationTypeNote.NOTATION_ABC">
    <lib-radio-buttons [isMandatory]="isMandatory" [group]="notationForm" [options]="OPTIONS_ABC" controlName="avis"> </lib-radio-buttons>
  </div>

  <div id="creation_notation_avis" class="select-container" *ngIf="typeNotation === EnumNotationTypeNote.NOTATION_1234">
    <lib-radio-buttons [isMandatory]="isMandatory" [group]="notationForm" [options]="OPTIONS_1234" controlName="avis"> </lib-radio-buttons>
  </div>
  <div class="ds-input-group__input w-100" [formGroup]="notationForm" *ngIf="typeNotation === EnumNotationTypeNote.NOTATION_NOTE_SUR_20">
    <input
      id="creation_notation_note"
      name="creation_notation_note"
      (keypress)="onkeypress($event)"
      placeholder="Avis sur 20, décimales acceptées"
      [attr.maxlength]="9"
      class="ds-input-group__input"
      formControlName="avis"
      #nameInput
    />
  </div>
  <div id="creation_notation_avis" class="select-container" *ngIf="typeNotation === EnumNotationTypeNote.NOTATION_FAVORABLE_DEFAVORABLE_RESERVE_AJOURNEE">
    <lib-radio-buttons [isMandatory]="isMandatory" [group]="notationForm" [options]="OPTIONS_FAV_DEFAV_RES_AJOURNEE" controlName="avis">
    </lib-radio-buttons>
  </div>
  <!-- Icône et message d'erreur -->
  <ng-container id="error-avis" *ngIf="notationForm.controls['avis'].invalid && notationForm.controls['avis'].touched">
    <div class="ds-input-group__feedback">
      Merci de renseigner un avis{{ typeNotation === EnumNotationTypeNote.NOTATION_NOTE_SUR_20 ? ' inférieur à 20' : ' ' }}
    </div>
  </ng-container>
</div>
