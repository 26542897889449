import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments-evaluateur/environment';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import {
  BatchUpdateComiteDecisionRequest,
  CentreMesure,
  ComiteProjet,
  ComiteSynthese,
  DateEcheanceRequest,
  DemandeComplement,
  DemandeCorrection,
  DemandeInterventionPI,
  Document,
  DocumentProjet,
  Domaine,
  DuplicationProjetInput,
  Eligibilite,
  Enquete,
  EnumApiVersion,
  EnumContractualisationStatus,
  EnumGrilleImpactProjetValidation,
  EnumInstructionStatus,
  EnumPhaseComite,
  EnumProjetEtape,
  EnumProjetStatut,
  EnumScope,
  EnumTypeDocument,
  ExportInputModel,
  FilterRsSiretInterface,
  Invitation,
  NotationExportInput,
  Projet,
  ProjetCompletudeSecondNiveau,
  ProjetInfo,
  ProjetLight,
  ProjetUpdateThematiqueModel,
  ReorientationAapInput,
  ResumeVerrouillage,
  SearchEvaluateurProjetModel,
  SearchObject,
  SearchObjectComites,
  Secteur,
  StatutValidation,
  SyntheseCompletudeSecondNiveau,
  SyntheseControleSecondNiveau,
  SyntheseInstructionInterface,
  SyntheseVerrouillage,
  UpdateComiteDecisionRequest,
  UpdateComitePropositionRequest,
  Verrouillage,
} from '@shared-ui';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ProjetService {
  apiUrl = environment.apiUrl;
  evalApiUrl = environment.apiUrl + 'evaluateurs/';
  instructeurApiUrl = environment.apiUrl + 'instructeur/';
  projetUrl = environment.apiUrl + 'projets';
  documentUrl = environment.apiUrl + 'documents';
  documentAdminUrl = environment.apiAdminUrl + 'documents';
  projetAdminUrl = environment.apiAdminUrl;
  projetAdminUrlV2 = environment.apiAdminUrlV2;
  notifUrl = environment.apiNotif;
  private projetsSubject = new BehaviorSubject(null);

  constructor(private httpClient: HttpClient, private sharedFunction: SharedFunction) {}

  private getApiUrl(): string {
    return this.sharedFunction.isSiteAdmin() ? this.projetAdminUrl : this.evalApiUrl;
  }

  public setProjetObservable(projet: Projet): void {
    this.projetsSubject.next(projet);
  }

  public getProjetObservable(): Observable<Projet> {
    return this.projetsSubject.asObservable();
  }

  searchLightProjetsForAdmin(searchCriteria: SearchObject): Observable<ProjetLight[]> {
    return this.httpClient.post<ProjetLight[]>(this.projetAdminUrl + 'projets/search-light', searchCriteria);
  }
  searchLightProjetsForInstructeurExterne(searchCriteria: SearchObject): Observable<ProjetLight[]> {
    return this.httpClient.post<ProjetLight[]>(this.projetUrl + '/search-light', searchCriteria);
  }
  searchLightProjetsForEvaluateur(searchCriteria: SearchEvaluateurProjetModel): Observable<ProjetLight[]> {
    return this.httpClient.post<ProjetLight[]>(`${this.evalApiUrl}projets/search-light`, searchCriteria);
  }

  searchProjetsForAdmin(searchCriteria: SearchObject): Observable<Projet[]> {
    return this.httpClient
      .post<{ projetLightDTOList: Projet[] }>(this.projetAdminUrl + 'projets/filtre', searchCriteria)
      .pipe(map((responseBody: { projetLightDTOList: Projet[] }) => responseBody.projetLightDTOList));
  }

  searchProjetsForEvaluateur(searchCriteria: SearchEvaluateurProjetModel): Observable<Projet[]> {
    return this.httpClient.post<Projet[]>(`${this.evalApiUrl}projets/filtres`, searchCriteria);
  }

  getProjetsTransverse(searchObject: SearchObject): Observable<Projet[]> {
    return this.httpClient
      .post<{ projetLightDTOList: Projet[] }>(this.getApiUrl() + 'projets/filtre-transverse', searchObject)
      .pipe(map((responseBody: { projetLightDTOList: Projet[] }) => responseBody.projetLightDTOList));
  }

  getProjetsComite(searchObject: SearchObjectComites): Observable<ComiteProjet[]> {
    return this.httpClient.post<ComiteProjet[]>(this.getApiUrl() + 'projets/filtre-comite', searchObject);
  }

  getProjetById(id: string): Observable<HttpResponse<Projet>> {
    return this.httpClient.get<Projet>(environment.apiUrl + 'projets/' + id, { observe: 'response' });
  }

  postProjet(projet: Projet): Observable<HttpResponse<Projet>> {
    return this.httpClient.post<Projet>(environment.apiAdminUrl + 'projets', JSON.stringify(projet), { observe: 'response' });
  }

  changeThematiqueProjet(projet: Projet): Observable<HttpResponse<Projet>> {
    const projetThematique = new ProjetUpdateThematiqueModel(projet.thematique, projet.dateModification);
    return this.httpClient.patch<Projet>(this.projetUrl + '/' + projet.id + '/thematique', JSON.stringify(projetThematique), {
      observe: 'response',
    });
  }

  upsertInfoGeneProjet(id: string, projetInfo: ProjetInfo): Observable<HttpResponse<void>> {
    return this.httpClient.post<void>(this.projetUrl + '/' + id + '/infos', JSON.stringify(projetInfo), { observe: 'response' });
  }

  upsertEnqueteProjet(id: string, enquete: Enquete): Observable<HttpResponse<Projet>> {
    return this.httpClient.put<Projet>(this.projetUrl + '/' + id + '/enquete-projet', JSON.stringify(enquete), { observe: 'response' });
  }

  upsertDocumentProjet(document: Document): Observable<HttpResponse<DocumentProjet>> {
    return this.httpClient.post<DocumentProjet>(this.documentUrl, JSON.stringify(document), {
      observe: 'response',
    });
  }

  getDocumentsProjet(idProjet: string, typesDocuments?: EnumTypeDocument[]): Observable<HttpResponse<DocumentProjet[]>> {
    return this.httpClient.get<DocumentProjet[]>(this.documentUrl, {
      params: this.buildHttpParamsForGetDocuments(idProjet, EnumScope.PROJET, null, typesDocuments),
      observe: 'response',
    });
  }

  getDocumentsStructure(
    idProjet: string,
    idStructure: string,
    typesDocuments?: EnumTypeDocument[]
  ): Observable<HttpResponse<DocumentProjet[]>> {
    return this.httpClient.get<DocumentProjet[]>(this.documentUrl, {
      params: this.buildHttpParamsForGetDocuments(idProjet, null, idStructure, typesDocuments),
      observe: 'response',
    });
  }

  private buildHttpParamsForGetDocuments(
    idProjet: string,
    scope?: EnumScope,
    idStructure?: string,
    typesDocuments?: EnumTypeDocument[]
  ): HttpParams {
    let params = new HttpParams();
    params = params.append('projetIds', idProjet);
    if (scope) {
      params = params.append('scope', scope);
    }
    if (idStructure) {
      params = params.append('structureId', idStructure);
    }
    if (typesDocuments && typesDocuments.length > 0) {
      params = params.appendAll({ typesDocuments });
    }
    return params;
  }

  putProjetVerrouillage(id: string, verrouillage: Verrouillage): Observable<HttpResponse<Projet>> {
    return this.httpClient.put<Projet>(this.getApiUrl() + 'projets/' + id + '/verrouillage', JSON.stringify(verrouillage), {
      observe: 'response',
    });
  }

  putProjetEligibilite(id: string, verrouillage: Eligibilite): Observable<HttpResponse<Projet>> {
    return this.httpClient.put<Projet>(this.getApiUrl() + 'projets/' + id + '/eligibilite', JSON.stringify(verrouillage), {
      observe: 'response',
    });
  }

  patchProjetSyntheseControleSecondNiveau(id: string, syntheseControle: SyntheseControleSecondNiveau): Observable<HttpResponse<void>> {
    return this.httpClient.patch<void>(
      this.getApiUrl() + 'projets/' + id + '/statut-synthese-controle-second-niveau',
      JSON.stringify(syntheseControle),
      {
        observe: 'response',
      }
    );
  }

  putDocumentVerrouillage(document: DocumentProjet): Observable<HttpResponse<DocumentProjet>> {
    return this.httpClient.put<DocumentProjet>(this.projetAdminUrl + 'documents/' + document.id + '/verrouillage', document.verrouillage, {
      observe: 'response',
    });
  }

  putDocumentEligibilite(document: DocumentProjet): Observable<HttpResponse<DocumentProjet>> {
    return this.httpClient.put<DocumentProjet>(this.projetAdminUrl + 'documents/' + document.id + '/eligibilite', document.eligibilite, {
      observe: 'response',
    });
  }

  getProjetResumeVerrouillage(id: string): Observable<HttpResponse<ResumeVerrouillage>> {
    return this.httpClient.get<ResumeVerrouillage>(this.getApiUrl() + 'projets/' + id + '/resume-verrouillage', {
      observe: 'response',
    });
  }

  getProjetSyntheseVerrouillage(id: string): Observable<HttpResponse<SyntheseVerrouillage>> {
    return this.httpClient.get<SyntheseVerrouillage>(this.getApiUrl() + 'projets/' + id + '/synthese-verrouillage', {
      observe: 'response',
    });
  }

  downloadArchiveUnitaire(id: string, docProject: boolean): Observable<HttpResponse<Projet>> {
    return this.httpClient.post<Projet>(this.apiUrl + 'archive?projectId=' + id + '&docProject=' + docProject, '', {
      observe: 'response',
    });
  }

  downloadArchiveById(id: string): Observable<HttpResponse<any>> {
    return this.httpClient.get<string>(this.apiUrl + 'archives/' + id, {
      observe: 'response',
    });
  }

  downloadDossiersComplets(projetsIds: string[]): Observable<HttpResponse<Projet>> {
    return this.httpClient.post<Projet>(
      this.apiUrl + 'documents/archive',
      { projetsIds },
      {
        observe: 'response',
      }
    );
  }

  deleteDocument(idProjet: string, idDocument: string, poleId?: string): Observable<object> {
    let queryPole = '';
    if (poleId) {
      queryPole = '&poleId=' + poleId;
    }
    return this.httpClient.delete(this.documentUrl + '/' + idDocument + '?projetId=' + idProjet + queryPole, { observe: 'response' });
  }

  updateProjetEtape(ids: string[], projet: Projet, etape: EnumProjetEtape, statut: EnumProjetStatut): Observable<HttpResponse<Projet>> {
    const etapeS = etape.toString();
    const statutS = statut.toString();

    let path: string;
    let isWorkflowRessource = false;
    switch (etapeS) {
      case EnumProjetEtape.PRE_DEPOT:
        switch (statutS) {
          case EnumProjetStatut.EN_ANALYSE:
            path = this.projetUrl + `/analyse-predepot?ids=` + ids;
            break;

          case EnumProjetStatut.ANALYSE_VALIDE:
            path = this.projetUrl + `/valider-analyse?ids=` + ids;
            break;

          case EnumProjetStatut.ANALYSE_REJETE:
            path = this.projetUrl + `/rejeter-analyse?ids=` + ids;
            break;

          case EnumProjetStatut.EN_EVALUATION:
            path = this.projetUrl + `/eval-predepot?ids=` + ids;
            break;

          case EnumProjetStatut.EVALUATION_VALIDE:
            path = this.projetUrl + `/valider-eval?ids=` + ids;
            break;

          case EnumProjetStatut.EVALUATION_REJETE:
            path = this.projetUrl + `/rejeter-eval?ids=` + ids;
            break;

          case EnumProjetStatut.EN_AUDITION:
            path = this.projetUrl + `/audition-predepot?ids=` + ids;
            break;

          case EnumProjetStatut.AUDITION_VALIDE:
            path = this.projetUrl + `/valider-audition?ids=` + ids;
            break;

          case EnumProjetStatut.AUDITION_REJETE:
            path = this.projetUrl + `/rejeter-audition?ids=` + ids;
            break;

          default:
            break;
        }
        break;

      case EnumProjetEtape.DEPOT:
        switch (statutS) {
          case EnumProjetStatut.EN_COURS:
            path = this.projetAdminUrl + `projets/wkf/depot-encours`;
            isWorkflowRessource = true;
            break;

          case EnumProjetStatut.EN_EVALUATION:
            path = this.projetAdminUrl + `projets/wkf/depot-evaluation-encours`;
            isWorkflowRessource = true;
            break;

          case EnumProjetStatut.EVALUATION_VALIDE:
            path = this.projetAdminUrl + `projets/wkf/depot-evaluation-validee`;
            isWorkflowRessource = true;
            break;

          case EnumProjetStatut.EVALUATION_REJETE:
            path = this.projetAdminUrl + `projets/wkf/depot-evaluation-rejetee`;
            isWorkflowRessource = true;
            break;

          case EnumProjetStatut.EN_AUDITION:
            path = this.projetAdminUrl + `projets/wkf/depot-audition-encours`;
            isWorkflowRessource = true;
            break;

          case EnumProjetStatut.AUDITION_VALIDE:
            path = this.projetAdminUrl + `projets/wkf/depot-audition-validee`;
            isWorkflowRessource = true;
            break;

          case EnumProjetStatut.AUDITION_REJETE:
            path = this.projetAdminUrl + `projets/wkf/depot-audition-rejetee`;
            isWorkflowRessource = true;
            break;

          case EnumProjetStatut.EN_ANALYSE:
            path = this.projetAdminUrl + `projets/wkf/depot-analyse-encours`;
            isWorkflowRessource = true;
            break;

          case EnumProjetStatut.ANALYSE_VALIDE:
            path = this.projetAdminUrl + `projets/wkf/depot-analyse-validee`;
            isWorkflowRessource = true;
            break;

          case EnumProjetStatut.ANALYSE_REJETE:
            path = this.projetAdminUrl + `projets/wkf/depot-analyse-rejetee`;
            isWorkflowRessource = true;
            break;

          default:
            break;
        }
        break;

      case EnumProjetEtape.INSTRUCTION:
        switch (statutS) {
          case EnumProjetStatut.EN_COURS:
            path = this.projetAdminUrl + `projets/instruction-encours`;
            isWorkflowRessource = true;
            break;

          case EnumProjetStatut.VALIDE:
            path = this.projetAdminUrl + `projets/wkf/instruction-validee`;
            isWorkflowRessource = true;
            break;

          case EnumProjetStatut.REJETE:
            path = this.projetAdminUrl + `projets/wkf/instruction-rejetee`;
            isWorkflowRessource = true;
            break;
          default:
            break;
        }
        break;

      case EnumProjetEtape.CONTRACTUALISATION:
        switch (statutS) {
          case EnumProjetStatut.EN_COURS:
            path = this.projetAdminUrl + `projets/wkf/contractualisation-encours`;
            isWorkflowRessource = true;
            break;

          case EnumProjetStatut.TERMINE:
            path = this.projetAdminUrl + `projets/wkf/contractualisation-terminee`;
            isWorkflowRessource = true;
            break;

          default:
            break;
        }
        break;

      default:
        break;
    }

    if (isWorkflowRessource) {
      return this.httpClient.patch<Projet>(path, JSON.stringify(ids), { observe: 'response' });
    }

    return this.httpClient.post<Projet>(path, JSON.stringify(projet), { observe: 'response' });
  }

  resendInvitation(id: string, idStructure: string, invitation: Invitation, idContact: string): Observable<HttpResponse<object>> {
    return this.httpClient.post(
      this.projetUrl + '/' + id + '/structures/' + idStructure + '/contacts/' + idContact + '/renvoyerinvitation',
      JSON.stringify(invitation),
      { observe: 'response' }
    );
  }

  downloadExportAffectationsProjets(exportInputModel: ExportInputModel): Observable<HttpResponse<string>> {
    return this.httpClient.post(this.projetUrl + '/export-affectations', exportInputModel, {
      responseType: 'text',
      observe: 'response',
    });
  }

  downloadEnquetes(projectIds: string[]): Observable<HttpResponse<string>> {
    return this.httpClient.post(this.apiUrl + 'projets/enquetes/export', JSON.stringify({ projectIds }), {
      responseType: 'text',
      observe: 'response',
    });
  }

  downloadNotationsProjets(notationExportInput: NotationExportInput): Observable<any> {
    return this.httpClient.post(this.projetAdminUrl + 'notations/export', JSON.stringify(notationExportInput), {
      responseType: 'text',
      observe: 'response',
    });
  }

  downloadExportProjets(exportInputModel: ExportInputModel): Observable<HttpResponse<string>> {
    return this.httpClient.post(this.projetUrl + '/export', exportInputModel, {
      responseType: 'text',
      observe: 'response',
    });
  }

  downloadExtraitDPM(projectIds: string[]): Observable<HttpResponse<string>> {
    return this.httpClient.post(this.apiUrl + 'projets/export-dpm', JSON.stringify({ projectIds }), {
      responseType: 'text',
      observe: 'response',
    });
  }

  putDateEcheance(dateEcheanceRequest: DateEcheanceRequest): Observable<HttpResponse<any>> {
    return this.httpClient.put(this.projetAdminUrl + 'projets/dates-echeances', JSON.stringify(dateEcheanceRequest), {
      observe: 'response',
    });
  }

  // RECHERCHE PROJETS

  /**
   * Retourne l'autocomplete demandé selon le champ de recherche
   * @param field champ de recherche (acronyme ou raison sociale etc)
   * @param input les champs de recherche saisis
   */
  getAutocomplete(field: string, input: SearchObject): Observable<HttpResponse<string[] | FilterRsSiretInterface[]>> {
    return this.httpClient.post<string[] | FilterRsSiretInterface[]>(
      this.sharedFunction.isSiteAdmin() ? this.projetAdminUrl : this.apiUrl + 'projets/' + field,
      JSON.stringify(input),
      {
        observe: 'response',
      }
    );
  }

  // DEMANDE DE CORRECTION
  createCorrection(demandesCorrection: DemandeCorrection): Observable<HttpResponse<object>> {
    return this.httpClient.post<Projet>(
      (this.sharedFunction.isSiteAdmin() ? this.projetAdminUrl : this.instructeurApiUrl) + 'demandes-correction',
      JSON.stringify(demandesCorrection),
      {
        observe: 'response',
      }
    );
  }

  reorienterAap(
    reorientationAapInput: ReorientationAapInput,
    apiVersion: EnumApiVersion = EnumApiVersion.V1
  ): Observable<HttpResponse<object>> {
    if (EnumApiVersion.V1 === apiVersion) {
      return this.reorienterAapV1(reorientationAapInput);
    } else if (EnumApiVersion.V2 === apiVersion) {
      return this.reorienterAapV2(reorientationAapInput);
    }
  }

  reorienterAapV1(reorientationAapInput: ReorientationAapInput): Observable<HttpResponse<object>> {
    return this.httpClient.post<Projet>(this.projetAdminUrl + 'projets/reorientation', JSON.stringify(reorientationAapInput), {
      observe: 'response',
    });
  }

  reorienterAapV2(reorientationAapInput: ReorientationAapInput): Observable<HttpResponse<object>> {
    return this.httpClient.post<Projet>(
      this.projetAdminUrlV2 + 'projets/' + reorientationAapInput.projetId + '/reorientation',
      JSON.stringify(reorientationAapInput),
      {
        observe: 'response',
      }
    );
  }

  dupliquerProjet(
    duplicationProjetInput: DuplicationProjetInput,
    apiVersion: EnumApiVersion = EnumApiVersion.V1
  ): Observable<HttpResponse<object>> {
    if (EnumApiVersion.V1 === apiVersion) {
      return this.dupliquerProjetV1(duplicationProjetInput);
    } else if (EnumApiVersion.V2 === apiVersion) {
      return this.dupliquerProjetV2(duplicationProjetInput);
    }
  }

  dupliquerProjetV1(duplicationProjetInput: DuplicationProjetInput): Observable<HttpResponse<object>> {
    return this.httpClient.post<Projet>(this.projetAdminUrl + 'projets/duplication', JSON.stringify(duplicationProjetInput), {
      observe: 'response',
    });
  }

  dupliquerProjetV2(duplicationProjetInput: DuplicationProjetInput): Observable<HttpResponse<object>> {
    return this.httpClient.post<Projet>(
      this.projetAdminUrlV2 + 'projets/' + duplicationProjetInput.projetId + '/duplication',
      JSON.stringify(duplicationProjetInput),
      {
        observe: 'response',
      }
    );
  }

  getDemandeDocComplements(projetId: string): Observable<HttpResponse<DemandeComplement[]>> {
    return this.httpClient.get<DemandeComplement[]>(this.projetAdminUrl + 'demande-complements?projetId=' + projetId, {
      observe: 'response',
    });
  }

  getCorrections(projetId: string, status?: string[]): Observable<HttpResponse<DemandeCorrection[]>> {
    const params = new HttpParams()
      .append('projetId', projetId)
      .appendAll({ statuts: status ?? ['OUVERT', 'CORRIGE', 'REJETE', 'VALIDE'] });

    return this.httpClient.get<DemandeCorrection[]>(`${this.instructeurApiUrl}demandes-correction`, {
      params: params,
      observe: 'response',
    });
  }

  updateCorrection(demandesCorrection: DemandeCorrection): Observable<HttpResponse<object>> {
    return this.httpClient.put<Projet>(
      (this.sharedFunction.isSiteAdmin() ? this.projetAdminUrl : this.instructeurApiUrl) + 'demandes-correction',
      JSON.stringify(demandesCorrection),
      {
        observe: 'response',
      }
    );
  }

  updateDemandeComplement(complementId: string, statut: string): Observable<HttpResponse<object>> {
    return this.httpClient.put<Projet>(
      (this.sharedFunction.isSiteAdmin() ? this.projetAdminUrl : this.instructeurApiUrl) +
        'demande-complements/' +
        complementId +
        '/statut',
      statut,
      {
        observe: 'response',
      }
    );
  }

  deleteDemandeComplement(complementId: string): Observable<object> {
    return this.httpClient.delete(
      (this.sharedFunction.isSiteAdmin() ? this.projetAdminUrl : this.instructeurApiUrl) + 'demande-complements/' + complementId,
      {
        observe: 'response',
      }
    );
  }

  autoAffectInstructeur(
    referent: boolean,
    intervenantPI: boolean,
    idProjets: string[],
    activites: string[]
  ): Observable<HttpResponse<any>> {
    const body = JSON.stringify({ referent, intervenantPI, idProjets, activites });
    return this.httpClient.post<HttpResponse<any>>(this.projetUrl + '/instructeurs', body, { observe: 'response' });
  }

  autoDeaffect(projetIds: string[]): Observable<Projet[]> {
    const params = new HttpParams().appendAll({ idsProjets: projetIds });
    return this.httpClient.delete<Projet[]>(`${this.projetUrl}/instructeurs`, { params });
  }

  public putProjetContractualisationStatus(id: string, status: EnumContractualisationStatus): Observable<Projet> {
    return this.httpClient.put<Projet>(this.projetAdminUrl + 'projets/' + id + '/contractualisation/statut-suivi', '"' + status + '"');
  }

  putInstructionStatus(id: string, status: EnumInstructionStatus): Observable<HttpResponse<Projet>> {
    return this.httpClient.put<Projet>(this.projetAdminUrl + `projets/${id}/instruction/statut`, status, {
      observe: 'response',
    });
  }

  putValidationGI(id: string, statut: EnumGrilleImpactProjetValidation): Observable<HttpResponse<Projet>> {
    const body = {
      statut: statut,
    };
    return this.httpClient.put<Projet>(this.projetAdminUrl + `projets/${id}/grille-impacts/validation/statut`, JSON.stringify(body), {
      observe: 'response',
    });
  }

  putDirectionRegionale(drdl: CentreMesure, projetsId: string[]): Observable<HttpResponse<Projet>> {
    const body = drdl;

    body.idsProjets = projetsId;

    return this.httpClient.put<Projet>(this.projetAdminUrl + `projets/centre-mesure`, JSON.stringify(body), {
      observe: 'response',
    });
  }

  putDateEnvoi(dataRequest: any): Observable<Projet[]> {
    return this.httpClient.patch<Projet[]>(this.projetAdminUrl + `projets/synthese-comite/date-envoi`, dataRequest);
  }

  putDecision(dateRequest: any, idComite: string) {
    return this.httpClient.patch<Projet[]>(this.projetAdminUrl + `projets/comites/` + idComite + `/decision-etat`, dateRequest);
  }

  patchPropositionComite(idProjet: string, request: UpdateComitePropositionRequest): Observable<HttpResponse<void>> {
    return this.httpClient.patch<void>(environment.apiAdminUrl + 'projets/' + idProjet + '/proposition-decision', JSON.stringify(request), {
      observe: 'response',
    });
  }

  patchDecisionComite(idProjet: string, request: UpdateComiteDecisionRequest): Observable<HttpResponse<void>> {
    return this.httpClient.patch<void>(environment.apiAdminUrl + `projets/${idProjet}/decision-comite`, JSON.stringify(request), {
      observe: 'response',
    });
  }

  patchBatchDecisionComite(request: BatchUpdateComiteDecisionRequest): Observable<HttpResponse<Projet[]>> {
    return this.httpClient.patch<Projet[]>(environment.apiAdminUrl + `projets/comites/decision-etat`, JSON.stringify(request), {
      observe: 'response',
    });
  }

  getProjetSynthesesComites(projetId: string, phase: EnumPhaseComite): Observable<ComiteSynthese[]> {
    return this.httpClient.get<ComiteSynthese[]>(`${environment.apiAdminUrl}projets/${projetId}/syntheses-comites`, {
      params: { phase },
    });
  }

  putProjetConfirmNotation(notationId: string, statut: string): Observable<object> {
    return this.httpClient.put(this.projetAdminUrl + `notations/${notationId}/statut-confirmation`, statut, {
      observe: 'response',
    });
  }

  pacthProjetSyntheseCompletudeSecondNiveauStatut(
    id: string,
    syntheseCompletudeSecondNiveau: SyntheseCompletudeSecondNiveau
  ): Observable<HttpResponse<Projet>> {
    return this.httpClient.patch<Projet>(
      this.getApiUrl() + 'projets/' + id + '/statut-synthese-completude-second-niveau',
      JSON.stringify(syntheseCompletudeSecondNiveau),
      {
        observe: 'response',
      }
    );
  }

  pacthProjetSyntheseCompletudeSecondNiveauCommentaire(
    id: string,
    syntheseCompletudeSecondNiveau: SyntheseCompletudeSecondNiveau
  ): Observable<HttpResponse<Projet>> {
    return this.httpClient.patch<Projet>(
      this.getApiUrl() + 'projets/' + id + '/commentaire-completude-seconde-niveau',
      JSON.stringify(syntheseCompletudeSecondNiveau),
      {
        observe: 'response',
      }
    );
  }

  getProjetSyntheseAnalyseSyntheseSecondeNiveau(projetId: string): Observable<HttpResponse<ProjetCompletudeSecondNiveau>> {
    return this.httpClient.get<ProjetCompletudeSecondNiveau>(
      this.getApiUrl() + 'projets/' + projetId + '/synthese-completude-second-niveau',
      { observe: 'response' }
    );
  }

  putDomaines(projetId: string, domaines: Domaine[]): Observable<HttpResponse<Domaine[]>> {
    return this.httpClient.put<Domaine[]>(environment.apiUrl + 'projets/' + projetId + '/domaines', JSON.stringify(domaines), {
      observe: 'response',
    });
  }

  putSecteurs(projetId: string, secteurs: Secteur[]): Observable<HttpResponse<Secteur[]>> {
    return this.httpClient.put<Secteur[]>(environment.apiUrl + 'projets/' + projetId + '/secteurs', JSON.stringify(secteurs), {
      observe: 'response',
    });
  }

  getSyntheseInstruction(projetId: string): Observable<HttpResponse<SyntheseInstructionInterface>> {
    return this.httpClient.get<SyntheseInstructionInterface>(`${environment.apiAdminUrl}projets/${projetId}/synthese-instruction`, {
      observe: 'response',
    });
  }

  patchSyntheseInstruction(projetId: string, synthese: SyntheseInstructionInterface): Observable<HttpResponse<Projet>> {
    return this.httpClient.patch<Projet>(`${environment.apiAdminUrl}projets/${projetId}/synthese-instruction`, JSON.stringify(synthese), {
      observe: 'response',
    });
  }

  getDocumentsAdmin(projetId: string, typesDocuments: EnumTypeDocument[]): Observable<HttpResponse<DocumentProjet[]>> {
    let params = new HttpParams();
    params = params.append('projetIds', projetId);
    params = params.appendAll({ typesDocuments });

    return this.httpClient.get<DocumentProjet[]>(this.documentAdminUrl, { params: params, observe: 'response' });
  }

  saveDocumentAdmin(document: Document): Observable<HttpResponse<DocumentProjet>> {
    return this.httpClient.post<DocumentProjet>(this.documentAdminUrl, JSON.stringify(document), {
      observe: 'response',
    });
  }

  deleteDocumentAdmin(idProjet: string, idDocument: string, poleId?: string): Observable<object> {
    let params = new HttpParams();
    params = params.append('projetId', idProjet);
    params = params.append('poleId', poleId);

    return this.httpClient.delete(this.documentAdminUrl + '/' + idDocument, { params: params, observe: 'response' });
  }

  updateStatutFicheCommunicationProjet(projetId: string, statusvalues: StatutValidation) {
    return this.httpClient.put<void>(
      `${environment.apiAdminUrl}projets/${projetId}/fiche-communication/statut`,
      JSON.stringify(statusvalues),
      {
        observe: 'response',
      }
    );
  }

  updateDemandeInterventionPI(projetId: string, dateRendu: DemandeInterventionPI) {
    return this.httpClient.patch<void>(
      `${environment.apiAdminUrl}projets/${projetId}/intervention-pi/demande-intervention`,
      JSON.stringify(dateRendu),
      {
        observe: 'response',
      }
    );
  }
}
