import { CritereReferentiel, Notation } from '@shared-ui';

export class SyntheseNotationCardHelperService {
  /**
   * Get display state tag of the note
   * @param notation
   * @param criteres
   * @returns
   */
  public static getTagNote(notation: Notation, criteres: CritereReferentiel[]): string | undefined {
    const A_RENSEIGNER = 'A renseigner';
    const PAS_DE_NOTE = 'Pas de note';
    const criteresMap = this.getCriteresMapById(criteres);
    if (notation.grilleEvaluation?.noteProjet) {
      return undefined;
    }
    if (notation.evaluateurSouhaiteNoter === false) {
      return PAS_DE_NOTE;
    }
    if (
      notation.evaluateurSouhaiteNoter &&
      notation.grilleEvaluation.evaluations?.length > 0 &&
      notation.grilleEvaluation.evaluations.filter(e => criteresMap.get(e.id)?.noteObligatoire).every(e => !!e.note)
    ) {
      return PAS_DE_NOTE;
    } else {
      return A_RENSEIGNER;
    }
  }

  public static getCriteresMapById(criteres: CritereReferentiel[]): Map<string, CritereReferentiel> {
    const criteresMap = new Map<string, CritereReferentiel>();
    if (!criteres) return criteresMap;
    criteres.forEach(c => {
      criteresMap.set(c.id, c);
    });
    return criteresMap;
  }
}
