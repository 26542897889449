import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RadioButtonOption } from '../../shared/models/radio-button-option.model';
import { EnumRadioType } from '../../shared/enums/enum.radio-type';

@Component({
  selector: 'lib-radio-buttons',
  templateUrl: './radio-buttons.component.html',
  styleUrls: ['./radio-buttons.component.scss'],
})
export class RadioButtonsComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() controlName: string;
  @Input() text: string;
  @Input() options: RadioButtonOption[] = [];
  @Input() errorMessage: string;
  @Input() isMandatory: boolean;
  @Input() index = '';
  @Input() infoText?: string;
  @Input() helpText?: string;
  @Input() isDisabled?: boolean;

  control: FormControl;
  disabled = '';

  enumRadioType = EnumRadioType;

  ngOnInit(): void {
    const formControl = this.group?.controls ? this.group?.controls[this.controlName] : undefined;
    if (formControl) {
      this.control = formControl as FormControl;
      this.disabled = this.control.status;
    } else {
      console.error(`controlName ${this.controlName} not found in the FormGroup`);
    }
  }
}
