import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[libRestrictSlashBackslash]',
})
export class RestrictSlashBackslashDirective {
  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    const invalidKeys = ['/', '\\'];
    if (invalidKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
