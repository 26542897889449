import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[libNumericComaDotOnly]',
})
export class NumericComaDotOnlyDirective {
  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    const digitOnlyRegex = new RegExp(/^[0-9.,]+$/g);
    if (event.key && !String(event.key).match(digitOnlyRegex)) {
      event.preventDefault();
    }
  }
}
