import { EnumNotationStatut } from '../enums/enum.NotationStatut';
import { EnumNotationStatutConfirmation } from '../enums/enum.NotationStatutConfirmation';
import {
  AvisExpertInterface,
  ExpertInterface,
  GrilleEvaluationInterface,
  InformationsProjetInterface,
  NotationCritereInterface,
  NotationDematInterface,
  PremierAvisEligibiliteInterface,
  PropositionExpertInterface,
} from '../interfaces/notation.interface';
import { DocumentNotation } from './documentNotation.model';
import { EvaluateurNotation } from './evaluateurNotation.model';
import { EnumNotationEtape } from '../enums/enumProjetEtape';
import { StatutPriseEnCompte } from './statut-prise-en-compte.model';

export class Notation implements NotationDematInterface {
  [key: string]: any;
  id: string;
  projetId: string;
  evaluateur: EvaluateurNotation;
  evaluateurMatricule: string;
  commentaire: string;
  note: string;
  etape: EnumNotationEtape;
  documents: DocumentNotation[] = [];
  dateCreation: Date;
  dateModification: Date;
  grilleEvaluation: GrilleEvaluation = new GrilleEvaluation();
  avis: string;
  avisExpert: AvisExpert;
  completudeDesDiapositivesAudition: boolean;
  informationsProjet: InformationsProjet;
  premierAvisEligibilite: PremierAvisEligibilite;
  propositionExpert: PropositionExpert;
  statut: EnumNotationStatut;
  statutConfirmation: EnumNotationStatutConfirmation;
  evaluateurSouhaiteNoter: boolean;
  commentairePasNote: string;
  interventionPI: boolean;
  statutPriseEnCompte: StatutPriseEnCompte;

  constructor() {
    this.evaluateur = new EvaluateurNotation();
    this.grilleEvaluation = new GrilleEvaluation();
    this.avisExpert = new AvisExpert();
    this.informationsProjet = new InformationsProjet();
    this.premierAvisEligibilite = new PremierAvisEligibilite();
    this.propositionExpert = new PropositionExpert();
  }
}

export class InformationsProjet implements InformationsProjetInterface {
  dureeEnMois!: number | undefined;
}

export class NotationCritere implements NotationCritereInterface {
  id: string;
  note: number;
  commentaire: string;
}

export class GrilleEvaluation implements GrilleEvaluationInterface {
  noteProjet: number;
  evaluations: NotationCritere[] = [];
}

export class AvisExpert implements AvisExpertInterface {
  avis: string;
  pointsForts: string;
  pointsFaibles: string;
  pointsAEclaircir: string;
  adequationCahierCharges: string;
  incitativeAide: string;
  pointEclaircirEudition: string;
  motifDeRefus: string;
  avisPostAudition: string;
  suggestionDeReorientation: string;
  voiesDeProgresPourEventuelleResoumission: string;
  interventionPI: boolean;
}

export class PremierAvisEligibilite implements PremierAvisEligibiliteInterface {
  indicateurs: NotationCritere[] = [];
  avisSurEligibilite: string;
}

export class Expert implements ExpertInterface {
  nom: string;
  prenom: string;
  email: string;
  secteur: string;
}

export class PropositionExpert implements PropositionExpertInterface {
  propositionExpert = true;
  experts: Expert[] = [];
}
