import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap, throwError } from 'rxjs';
import { environment } from '@environments-evaluateur/environment';
import { ShowToastrService, Utilisateur, UtilisateurAbstractService } from '@shared-ui';
import { PxlOidcService } from './pxl-oidc.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class UtilisateurService extends UtilisateurAbstractService {
  constructor(protected httpClient: HttpClient, private showToastrService: ShowToastrService, private oidcSecurityService: PxlOidcService) {
    super(environment, httpClient);
  }

  getUtilisateur(): Observable<Utilisateur> {
    return this.getUserInfos().pipe(
      map(response => response.body!),
      tap(user => {
        this.oidcSecurityService.getUserData().subscribe(userData => {
          user.firstName = userData?.firstName || user.prenom;
          user.lastName = userData?.lastName || user.nom;
          user.emailAddress = userData?.email || user.email;
        });
        this.setUserObservable(user);
        this.setUserObservableForCGU(user);
      }),
      catchError((err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
        return throwError(() => err);
      })
    );
  }

  public patchUserAapFavorites(aapIds: string[]): Observable<HttpResponse<Utilisateur>> {
    return this.httpClient.patch<Utilisateur>(environment.apiAdminUrl + 'utilisateurs/aaps-favoris', JSON.stringify(aapIds), {
      observe: 'response',
    });
  }

  public putCguUser(): Observable<Utilisateur> {
    return this.httpClient.post<Utilisateur>(environment.apiUrl + 'utilisateurs/cgu', {});
  }
}
