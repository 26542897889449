<nav class="ds-menu sidenav-wrapper" id="menu">
  <div class="sidenav">
    <ul class="ds-menu__group sidenav__menu">
      <li
        routerLinkActive="sidenav__link--active"
        matTooltip="Appels à projet"
        *ngIf="PermissionUtils.hasPermissionIgnoringScope(user, EnumPermissionUtilisateur.AAP_READ)"
      >
        <a routerLink="/aaps" class="sidenav__link">
          <lib-pxl-icon class="sidenav__link-icon" icon="icon-menu-megaphone-in"></lib-pxl-icon>
          <div class="sidenav__link-label">Appels à projet</div>
        </a>
        <a routerLink="/creationAap" style="display: none"></a>
      </li>
      <li
        matTooltip="Recherche transverse"
        routerLinkActive="sidenav__link--active"
        *ngIf="
          PermissionUtils.hasPermission(user, EnumPermissionUtilisateur.RECHERCHE_TRANSVERSE_READ) ||
          PermissionUtils.hasPermissionOnProgramme(user, EnumPermissionUtilisateur.RECHERCHE_TRANSVERSE_READ)
        "
      >
        <a routerLink="/recherche-transverse" class="sidenav__link">
          <lib-pxl-icon class="sidenav__link-icon" icon="icon-other-search"></lib-pxl-icon>
          <div class="sidenav__link-label">Recherche transverse</div>
        </a>
        <a routerLink="/recherche-transverse" style="display: none"></a>
      </li>
      <li
        *ngxPermissionsOnly="['COMITE_READ', 'COMITE_WRITE']"
        matTooltip="Comités"
        routerLinkActive="sidenav__link--active"
        [libMyRemoveIfFeatureOff]="EnumFeatureFlipping.COMITES"
      >
        <a routerLink="/comites" class="sidenav__link">
          <lib-pxl-icon class="sidenav__link-icon" icon="icon-menu-organisme"></lib-pxl-icon>
          <div class="sidenav__link-label">comite(s)</div>
        </a>
        <a routerLink="/comites" style="display: none"></a>
      </li>
      <li routerLinkActive="sidenav__link--active" matTooltip="Organismes" [libMyRemoveIfFeatureOff]="EnumFeatureFlipping.ORGANISMES">
        <a routerLink="/organismes" class="sidenav__link">
          <lib-pxl-icon class="sidenav__link-icon" icon="icon-bank"></lib-pxl-icon>
          <div class="sidenav__link-label">Organismes</div>
        </a>
        <a routerLink="/organismes" style="display: none"></a>
      </li>
      <li
        *ngIf="PermissionUtils.hasPermission(user, EnumPermissionUtilisateur.PXL_QLIK_READ)"
        matTooltip="Reporting DE - Qlik"
        [libMyRemoveIfFeatureOff]="EnumFeatureFlipping.QLIK"
      >
        <a style="cursor: pointer" class="sidenav__link" (click)="goToQlik()">
          <lib-pxl-icon class="sidenav__link-icon" icon="pie-chart"></lib-pxl-icon>
          <div class="sidenav__link-label">Qlik</div>
        </a>
      </li>
      <li
        matTooltip="'Feature Flipping admin console'"
        routerLinkActive="sidenav__link--active"
        *ngIf="PermissionUtils.hasPermission(user, EnumPermissionUtilisateur.FEATURE_FLIPPING)"
      >
        <pxl-nav-item title="Feature Flipping" iconName="icon-action-replace" routerLink="/feature-flipping"> </pxl-nav-item>
      </li>
    </ul>
  </div>
</nav>
<div class="ds-menu-mobile">
  <div id="toggle-menu" class="ds-menu-mobile__open-area" onclick="toggle()">
    <em class="ds-icon" aria-hidden="true">menu</em>&nbsp;Menu
  </div>
  <div id="close" class="ds-menu-mobile__close-area" onclick="close()"></div>
</div>
