import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfirmModalComponent, DocumentBdd, ShowToastrService } from '@shared-ui';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'lib-button-upload-files',
  templateUrl: './button-upload-files.component.html',
  styleUrls: ['./button-upload-files.component.scss'],
})
export class ButtonUploadFilesComponent {
  @Input() canUserWriteContractualisation: boolean;
  @Input() canUserWriteEligibilite: boolean;
  @Input() ACCEPTED_FILE_TYPES: any;
  @Input() fileTooBig: boolean;
  @Input() fileNotSupported: boolean;
  @Input() documentBdd: DocumentBdd;
  @Input() labelbutton: string;
  @Output() uploadFileEventEmitter = new EventEmitter<any>();

  fileToUpload: File;
  maxSize = 10000000;
  isUploading = false;
  docSuccess = false;

  constructor(public showToastrService: ShowToastrService, public dialog: MatDialog) {}

  onSelectDoc($event: any) {
    this.docSuccess = false;
    this.fileTooBig = false;
    this.fileNotSupported = false;
    this.fileToUpload = $event.target.files[0];

    if (this.fileToUpload.size >= this.maxSize) {
      this.fileTooBig = true;
      this.showToastrService.error('Le fichier à importer doit avoir une taille maximale de 10Mo.');
      return;
    }

    if (!['text/csv'].includes(this.fileToUpload.type)) {
      this.fileNotSupported = true;
      return;
    }

    if (this.documentBdd == null) {
      this.uploadFileEventEmitter.emit(this.fileToUpload);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Ecraser les données existantes ?',
        description: `<p>Lors du nouvel import, les nouvelles données écraseront les précédentes données importées.</p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Annuler',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.uploadFileEventEmitter.emit(this.fileToUpload);
      }
    });
  }
}
