import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  Aap,
  ComiteProposition,
  ComitePropositionInterfaceForm,
  CSV,
  DateTimeUtils,
  Dispositif,
  DOC,
  DocumentAsyncUploaderComponent,
  DocumentProjet,
  DOCX,
  EnumConvention,
  EnumDecisionComite,
  EnumPhaseComite,
  EnumScope,
  EnumTypeDocument,
  FILE_SIZE,
  FlechageBudgetaireItem,
  JPEG,
  JPG,
  Notation,
  PDF,
  PPT,
  PPTX,
  Projet,
  ShowToastrService,
  UpdateComitePropositionRequest,
  Utilisateur,
  XLS,
  XLSX,
} from '@shared-ui';
import { ProjetPropositionComiteSyntheseHelperService } from './projet-proposition-comite-synthese.helper.service';
import { FlechageBudgetaireService } from '@services-evaluateur/flechage-budgetaire.service';
import { ProjetService } from '@services-evaluateur/projet.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, EMPTY, Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'pxl-projet-proposition-comite-synthese',
  templateUrl: './projet-proposition-comite-synthese.component.html',
})
export class ProjetPropositionComiteSyntheseComponent implements OnInit, OnChanges {
  private destroyRef = inject(DestroyRef);

  readonly ACCEPTED_DOCUMENTS = [PDF, XLS, XLSX, DOC, DOCX, PPT, PPTX, JPG, JPEG, CSV];

  @ViewChild('documentAsyncUploaderComponent') documentAsyncUploaderComponent!: DocumentAsyncUploaderComponent;

  propositionForm!: FormGroup<ComitePropositionInterfaceForm>;

  @Input() utilisateur!: Utilisateur;
  @Input() notations: Notation[] = [];
  @Input() proposition!: ComiteProposition;
  @Input() projet!: Projet;
  @Input() phase!: EnumPhaseComite;
  @Input() canUserWrite!: boolean;
  @Input() hideNotationAverage?: boolean;
  @Input() aap!: Aap;
  @Input() dispositifs: Dispositif[] = [];
  @Input() dispositifDefaultValue?: string;
  @Input() withRapportInstruction = false;
  @Input() projetRapportInstructionDocuments: DocumentProjet[] = [];
  @Output() propositionSaved = new EventEmitter();

  rapportInstructionDocuments: DocumentProjet[] = [];

  protected readonly FILE_SIZE = FILE_SIZE;

  isPhaseInstruction!: boolean;
  dispositifOptions: FlechageBudgetaireItem[] = [];
  notationsAverage!: number;

  constructor(
    public helperService: ProjetPropositionComiteSyntheseHelperService,
    public flechageBudgetaireService: FlechageBudgetaireService,
    private projetService: ProjetService,
    private showToastrService: ShowToastrService
  ) {
    this.propositionForm = this.helperService.buildFormSynthese();
  }

  ngOnInit(): void {
    this.isPhaseInstruction = this.phase === EnumPhaseComite.SELECTION;
  }

  setDispositifOptions() {
    if (this.aap && this.dispositifs) {
      this.dispositifOptions = this.flechageBudgetaireService.buildDispositifsListWithAap(
        this.aap,
        this.dispositifs,
        this.proposition?.dispositif
      );
    }
  }

  setDefaultDispositifValue() {
    if (this.dispositifDefaultValue && this.proposition?.dispositif == null) {
      this.propositionForm?.patchValue({ dispositif: this.dispositifDefaultValue });
    }
  }

  setNotationsAverage() {
    this.notationsAverage =
      this.proposition?.moyenneNotations ?? (this.notations ? this.helperService.getNotationsAverage(this.notations) : 0);
  }

  setRapportInstructionDocuments() {
    this.rapportInstructionDocuments = this.projetRapportInstructionDocuments.filter(doc =>
      (this.proposition?.rapportInstructionIds ?? []).includes(doc.id)
    );
  }

  saveSynthese(): void {
    if (this.isFormEmpty()) {
      this.showToastrService.error('Vous ne pouvez pas enregistrer une proposition vide.');
      return;
    }

    if (this.withRapportInstruction) {
      this.saveDocuments().subscribe({
        next: saved => this.saveProposition(saved),
      });
    } else {
      this.saveProposition();
    }
  }

  saveProposition(savedRapportInstruction?: { document: DocumentProjet; isNew: boolean }[]): void {
    const formValue = this.propositionForm.getRawValue();

    const proposition: ComiteProposition = {
      avis: formValue.avis!,
      dateEnvoiValidationEtat: DateTimeUtils.toUtcDate(formValue.dateEnvoiValidationEtat!),
      syntheseADestinationEtat: formValue.syntheseADestinationEtat!,
      dispositif: formValue.dispositif!,
      convention: formValue.convention!,
      grandPrix: (this.isPhaseInstruction && this.aap.ilab ? formValue.grandPrix : null)!,
      rapportInstructionIds: savedRapportInstruction && savedRapportInstruction.map(s => s.document.id),
    };
    const request: UpdateComitePropositionRequest = {
      phase: this.phase,
      ...proposition,
    };

    this.projetService
      .patchPropositionComite(this.projet.id, request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.showToastrService.success(`Synthèse de l’${EnumPhaseComite.toLowerCaseString(this.phase)} enregistrée.`);
          this.proposition = proposition;
          this.propositionSaved.emit();
          this.resetSynthese();
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
          // revert documents save
          (savedRapportInstruction ?? [])
            .filter(s => s.isNew)
            .map(s => s.document)
            .forEach(doc => this.documentAsyncUploaderComponent.deleteDocument(doc));
        },
      });
  }

  saveDocuments(): Observable<{ document: DocumentProjet; isNew: boolean }[]> {
    return this.documentAsyncUploaderComponent.saveDocuments().pipe(
      takeUntilDestroyed(this.destroyRef),
      switchMap(result => {
        if (result) {
          return of(this.rapportInstructionDocuments.map(document => ({ document, isNew: !document.id })));
        } else {
          return of([]);
        }
      }),
      catchError(err => this.handleError(err))
    );
  }

  getDocFieldsCompleter(): (document: DocumentProjet) => void {
    return (document: DocumentProjet) => {
      document.typeDoc = EnumTypeDocument.RAPPORT_INSTRUCTION;
      document.projetId = this.projet.id;
      document.createur = this.utilisateur.matricule;
      document.scope = EnumScope.PROJET;
    };
  }

  deleteDocument(document: DocumentProjet): void {
    this.documentAsyncUploaderComponent.deleteDocument(document);
  }

  onRapportInstructionDocumentDeleted(document: DocumentProjet) {
    this.proposition.rapportInstructionIds = this.proposition.rapportInstructionIds?.filter(id => id !== document.id);
    const request: UpdateComitePropositionRequest = {
      phase: this.phase,
      ...this.proposition,
    };
    this.projetService.patchPropositionComite(this.projet.id, request).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  resetSynthese(): void {
    this.propositionForm.reset(this.proposition);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.proposition && this.proposition) {
      this.propositionForm?.patchValue(this.proposition);
    }
    if (changes.dispositifs && this.dispositifs) {
      this.setDispositifOptions();
    }
    if (changes.dispositifDefaultValue && this.phase !== EnumPhaseComite.AUDITION) {
      this.setDefaultDispositifValue();
    }
    if (changes.notations || changes.proposition) {
      this.setNotationsAverage();
    }
    if (changes.projetRapportInstructionDocuments && this.withRapportInstruction) {
      this.setRapportInstructionDocuments();
    }
    if (changes.canUserWrite && this.propositionForm) {
      this.changeFormState();
    }
  }

  changeFormState() {
    if (!this.canUserWrite) {
      this.propositionForm?.disable();
    } else {
      this.propositionForm?.enable();
    }
  }

  isFormEmpty(): boolean {
    return (
      this.propositionForm.controls.avis.value == null &&
      this.propositionForm.controls.dateEnvoiValidationEtat.value == null &&
      this.propositionForm.controls.dispositif.value == null &&
      this.propositionForm.controls.convention.value == null &&
      (this.propositionForm.controls.syntheseADestinationEtat.value == null ||
        this.propositionForm.controls.syntheseADestinationEtat.value.trim() == '')
    );
  }

  isFormDirty() {
    return this.propositionForm.dirty;
  }

  handleError(err: HttpErrorResponse): Observable<never> {
    this.showToastrService.checkCodeError(err?.error);
    return EMPTY;
  }

  protected readonly EnumPhaseComite = EnumPhaseComite;
  protected readonly EnumDecisionComite = EnumDecisionComite;
  protected readonly EnumConvention = EnumConvention;
}
