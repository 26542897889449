import { FormGroup } from '@angular/forms';
import { environment, SITE_ADMIN, SITE_EVAL } from '@environments-evaluateur/environment';
import {
  Aap,
  Comite,
  DocumentAapModel,
  EnumActivite,
  EnumCriteresReferentielOptions,
  EnumPermissionUtilisateur,
  EnumPhaseType,
  EnumProjetEtape,
  EnumProjetStatut,
  EnumRoleContact,
  EnumRolePartenaire,
  EnumRoleStructure,
  EnumScope,
  EnumStatutComite,
  EnumTypeWorkflow,
  EnumUserGroup,
  Evaluateur,
  PermissionUtils,
  Projet,
  Structure,
  Utilisateur,
} from '@shared-ui';

export class SharedFunction {
  rl = false;
  rp = false;

  getProjectEtapeName(projet: Projet): EnumProjetEtape | null {
    if (projet?.etapes == null) {
      return null;
    }
    return projet?.etapes[projet?.etapes.length - 1].nom;
  }

  getProjectEtapeStatut(projet: Projet): EnumProjetStatut | null {
    if (projet?.etapes == null) {
      return null;
    }
    return projet?.etapes[projet?.etapes.length - 1].statut;
  }

  isUserGroup(user: Utilisateur): boolean {
    return user?.groupe == EnumUserGroup.PXL_USER_GROUPE;
  }

  isUserInno(user: Utilisateur): boolean {
    return user?.groupe == EnumUserGroup.PXL_USERINNO_GROUPE;
  }

  isUserDar(user: Utilisateur): boolean {
    return user?.groupe === EnumUserGroup.PXL_USERDAR_GROUPE;
  }

  isProjectUpdatableByUser(projet: Projet, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_WRITE, projet);
  }

  isStructureUpdatableByUser(projet: Projet, structure: Structure, user: Utilisateur): boolean {
    return this.isProjectUpdatableByUser(projet, user) && !structure.closed;
  }

  isAapUpdatableByUser(utilisateur: Utilisateur): boolean {
    return PermissionUtils.hasPermission(utilisateur, EnumPermissionUtilisateur.PARAMETRAGE_WRITE) && this.isSiteAdmin();
  }

  canUserWriteOnEvaluation(utilisateur: Utilisateur, projet: Projet, comite?: Comite): boolean {
    if (comite?.statut === EnumStatutComite.CLOTURE) {
      return false;
    }
    return this.isUserAffectedToWriteNotation(utilisateur, projet, EnumActivite.OPPORTUNITE);
  }

  canUserWriteOnAudition(utilisateur: Utilisateur, projet: Projet, comite?: Comite): boolean {
    if (comite?.statut === EnumStatutComite.CLOTURE) {
      return false;
    }
    return this.isUserAffectedToWriteNotation(utilisateur, projet, EnumActivite.AUDITION);
  }

  isUserAffectedToWriteNotation(utilisateur: Utilisateur, projet: Projet, activite: EnumActivite): boolean {
    return PermissionUtils.hasPermissionOnProjet(
      utilisateur,
      activite === EnumActivite.OPPORTUNITE
        ? EnumPermissionUtilisateur.PROJET_EVALUATION_WRITE
        : EnumPermissionUtilisateur.PROJET_AUDITION_WRITE,
      projet
    );
  }

  isUserReadOnlyOnEvaluation(utilisateur: Utilisateur, projet: Projet, comite?: Comite): boolean {
    return (
      !this.canUserWriteOnEvaluation(utilisateur, projet, comite) &&
      PermissionUtils.hasPermissionOnProjet(utilisateur, EnumPermissionUtilisateur.PROJET_EVALUATION_READ, projet)
    );
  }

  isUserReadOnlyOnAudition(utilisateur: Utilisateur, projet: Projet, comite?: Comite): boolean {
    return (
      !this.canUserWriteOnAudition(utilisateur, projet, comite) &&
      PermissionUtils.hasPermissionOnProjet(utilisateur, EnumPermissionUtilisateur.PROJET_AUDITION_READ, projet)
    );
  }

  canUserEvaluateProject(user: Utilisateur, projet: Projet): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_EVALUATION_WRITE, projet);
  }

  canUserAuditProject(user: Utilisateur, projet: Projet): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_AUDITION_WRITE, projet);
  }

  canUserNoteProjectInstruction(user: Utilisateur, projet: Projet): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_INSTRUCTION_WRITE, projet);
  }

  canUserReadEvaluations(user: Utilisateur, projet: Projet): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_EVALUATION_READ, projet);
  }

  canUserReadAllAuditions(user: Utilisateur, projet: Projet): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_AUDITION_READ_ALL, projet);
  }

  canUserReadAuditions(user: Utilisateur, projet: Projet): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_AUDITION_READ, projet);
  }

  isUserAffectedToProjectActivity(projet: Projet, user: Utilisateur): boolean {
    return (
      projet?.instructeurs?.find(
        instructeur => instructeur.matricule === user.matricule && instructeur.actif && instructeur.activites.length > 0
      ) != null
    );
  }

  isUserAffectedToOneOfProjectActivities(projet: Projet, user: Utilisateur, activites: EnumActivite[]): boolean {
    return projet?.instructeurs?.some(
      instructeur =>
        instructeur.matricule === user.matricule &&
        instructeur.actif &&
        instructeur.activites.length > 0 &&
        instructeur.activites.find(a => activites.includes(a.nomActivite))
    );
  }

  isUserAffectedToOneOfProjectActivity(projet: Projet, user: Utilisateur, activite: EnumActivite): boolean {
    return (
      projet?.instructeurs?.find(
        instructeur =>
          instructeur.matricule === user.matricule &&
          instructeur.actif &&
          instructeur.activites.length > 0 &&
          instructeur.activites.find(a => a.nomActivite === activite)
      ) != null
    );
  }

  isEvaluateurAffectedToOneOfProjectActivity(
    projet: Projet,
    evaluateurs: Evaluateur[],
    user: Utilisateur,
    activite: EnumActivite
  ): boolean {
    return (
      evaluateurs?.find(
        evaluateur =>
          evaluateur.matricule === user.matricule &&
          evaluateur.listAffectationProjets.length > 0 &&
          evaluateur.listAffectationProjets.find(
            affectationProjet => affectationProjet.id === projet.id && affectationProjet.notationEtapes.includes(activite)
          )
      ) != null
    );
  }

  canUserReadVerrouillage(user: Utilisateur, projet: Projet): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_VERROUILLAGE_READ, projet);
  }

  canUserWriteVerrouillage(user: Utilisateur, projet: Projet): boolean {
    return this.canUserWriteActivity(user, projet, EnumPermissionUtilisateur.PROJET_VERROUILLAGE_WRITE, EnumActivite.VERROUILLAGE);
  }

  canUserReadEligibilite(user: Utilisateur, projet: Projet): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_ELIGIBILITE_READ, projet);
  }

  canUserWriteEligibilite(user: Utilisateur, projet: Projet): boolean {
    return this.canUserWriteActivity(user, projet, EnumPermissionUtilisateur.PROJET_ELIGIBILITE_WRITE, EnumActivite.ELIGIBILITE);
  }

  canUserReadContractualisation(user: Utilisateur, projet?: Projet): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_CONTRACTUALISATION_READ, projet);
  }

  canUserWriteContractualisation(user: Utilisateur, projet: Projet): boolean {
    return this.canUserWriteActivity(
      user,
      projet,
      EnumPermissionUtilisateur.PROJET_CONTRACTUALISATION_WRITE,
      EnumActivite.CONTRACTUALISATION
    );
  }

  canUserReadInstruction(user: Utilisateur, projet: Projet): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_INSTRUCTION_READ, projet);
  }

  canUserWriteInstruction(user: Utilisateur, projet: Projet): boolean {
    return this.canUserWriteActivity(user, projet, EnumPermissionUtilisateur.PROJET_INSTRUCTION_WRITE, EnumActivite.INSTRUCTION);
  }

  canUserReadInstructionIlabEval(user: Utilisateur, ilab: boolean, projet: Projet, activite: EnumActivite): boolean {
    return (
      ilab &&
      (this.isUserAffectedToOneOfProjectActivity(projet, user, activite) ||
        PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_INSTRUCTION_READ, projet))
    );
  }

  canUserWriteActivity(user: Utilisateur, projet: Projet, permissionWrite: EnumPermissionUtilisateur, activite: EnumActivite): boolean {
    if (this.isUserInno(user) || this.isUserAffectedToOneOfProjectActivity(projet, user, activite)) {
      return PermissionUtils.hasPermissionOnProjet(user, permissionWrite, projet);
    }
    return false;
  }

  canUserEditComite(user: Utilisateur, comite: Comite): boolean {
    if (comite.statut === EnumStatutComite.CLOTURE) {
      return false;
    }
    return (
      comite?.auteur?.matricule === user?.matricule || // créateur
      this.isUserAffectedToComite(user, comite) // auto affecté
    );
  }

  isUserAffectedToComite(user: Utilisateur, comite?: Comite): boolean {
    return comite?.instructeurs?.find(instructeur => instructeur.matricule === user.matricule && instructeur.actif) != null;
  }

  isUserCreatorOfComite(user: Utilisateur, comite?: Comite): boolean {
    return comite?.auteur?.matricule === user?.matricule;
  }

  isStepStatutReached(statut: EnumProjetStatut, projet: Projet): boolean {
    return !!projet.etapes?.filter(step => step.statut === statut)[0];
  }

  /* Vérifie si les structures sont complètes
   * */
  checkIncompleteCompanies(structures: Structure[], projet: Projet, aap: Aap): Structure[] {
    return structures.filter(structure => {
      return this.checkIncompleteCompany(structure, projet, aap);
    });
  }

  /* Vérifie si la structure est complète
   * */
  checkIncompleteCompany(structure: Structure, projet: Projet, aap: Aap): boolean {
    if (structure.closed) {
      return false;
    }

    if (
      (this.getProjectEtapeName(projet) === EnumProjetEtape.PRE_DEPOT && !aap.budgetEstime && !structure.budgetPreDepot) ||
      (this.getProjectEtapeName(projet) === EnumProjetEtape.DEPOT && !structure.budgetDepot)
    ) {
      return true;
    }

    if (!structure.adresse || !structure.adresse?.cp) {
      return true;
    }

    if (!structure?.lieuRD?.raisonSocial && structure.role !== EnumRoleStructure.MANDATAIRE) {
      return true;
    }

    return this.checkIncompleteContacts(structure);
  }

  checkIncompleteContacts(structure: Structure): boolean {
    if (structure.role === EnumRoleStructure.MANDATAIRE) {
      if (structure.contacts?.length > 0) {
        for (const contact of structure.contacts) {
          if (!contact.nom || !contact.prenom || !contact.telephone || !contact.email) {
            return true;
          }
        }
      } else {
        return true;
      }

      // return false si contact complet
      return false;
    } else {
      return this.checkMissingRlRp(structure);
    }
  }

  checkMissingRlRp(structure: Structure) {
    let rl = false;
    let rp = false;
    return !structure.contacts?.some(contact => {
      if (!contact.nom || !contact.prenom || !contact.telephone || !contact.email) {
        return false;
      }
      if (contact.roles?.length > 0) {
        contact.roles.forEach(role => {
          if (role === (EnumRoleContact as any)[EnumRoleContact.REPRESENTANT_LEGAL.toString()] && contact.paysNaissance) {
            rl = true;
          }
        });

        if (contact.roles.indexOf((EnumRoleContact as any)[EnumRoleContact.RESPONSABLE_PROJET.toString()]) !== -1) {
          rp = true;
        }
      }
      return rl && rp;
    });
  }

  /*
   * Vérifie si les informations du projet sont remplies
   * */
  checkProjetInfo(projet: Projet): boolean {
    return projet.nom == null && projet.description == null;
  }

  /*
   * Vérifie si le budget du projet est remplie
   * */
  checkBudgetInfo(projet: Projet): boolean {
    return projet.budget == null;
  }

  /*
   * Vérifie si on est sur site ADMIN
   * */
  isSiteAdmin(): boolean {
    return environment.site === SITE_ADMIN;
  }

  /*
   * Vérifie si on est sur site EVALUATEUR
   * */
  isSiteEval(): boolean {
    return environment.site === SITE_EVAL;
  }

  isEvalPoleLabellisateur(evaluateur: Evaluateur, projet: Projet): boolean {
    return (
      evaluateur?.listAffectationProjets != null &&
      evaluateur?.listAffectationProjets.find(
        affectation => affectation.id === projet.id && affectation.utilisateurRole === 'POLE_LABELLISATEUR'
      )
    );
  }

  isEvaluateurInstructeurExterne(evaluateur: Evaluateur): boolean {
    return evaluateur?.type === EnumRolePartenaire.INSTRUCTEUR_EXTERNE || evaluateur?.type === EnumRolePartenaire.INSTRUCTEUR_VALIDEUR;
  }

  isAutoAffectableOnAap(aap: Aap, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnAap(user, EnumPermissionUtilisateur.AUTOAFFECTATION_PROJET, aap);
  }

  isAutoAffectableToProjet(projet: Projet, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.AUTOAFFECTATION_PROJET, projet);
  }

  canUserExtractAapProjets(aap: Aap, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnAap(user, EnumPermissionUtilisateur.EXTRACTION_PROJET_PARTENAIRE, aap);
  }

  canUserReadDemandeCorrection(projet: Projet, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_DEMANDECORRECTION_READ, projet);
  }

  canUserWriteDemandeCorrection(projet: Projet, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_DEMANDECORRECTION_WRITE, projet);
  }

  canUserReadDemandeComplement(projet: Projet, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_DEMANDE_COMPLEMENT_READ, projet);
  }

  canUserWriteDemandeComplement(projet: Projet, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_DEMANDE_COMPLEMENT_WRITE, projet);
  }

  canUserUpdateWorkflow(projet: Projet, aap: Aap, user: Utilisateur): boolean {
    return (
      (aap.typeWKF == EnumTypeWorkflow.WKF1 &&
        PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.WKF_PREDEPOT, projet)) ||
      (aap.typeWKF == EnumTypeWorkflow.WKF2 && PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.WKF_DEPOT, projet))
    );
  }
  canEvaluateurFilterProjects(aap: Aap, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnAap(user, EnumPermissionUtilisateur.PROJET_FILTERS, aap);
  }
  /**
   *
   * Vérifie si le responsable projet des contacts de la structure est le même que le responsable projet déclarer dans les membres d'équipe
   * */
  checkEquipeAndContactHasValidResponsable(structure: Structure): boolean {
    if (!structure.contacts?.length || !structure.equipe?.length) {
      return true;
    }
    const responsableContact = structure.contacts.find(
      contact => contact.roles?.indexOf((EnumRoleContact as any)[EnumRoleContact.RESPONSABLE_PROJET.toString()]) !== -1
    );
    const responsableEquipe = structure.equipe.find(equipe => !!equipe.identite?.responsableProjet);
    if (!responsableContact || !responsableEquipe) {
      return true;
    }
    return (
      responsableContact.nom?.toLocaleLowerCase() === responsableEquipe.identite?.nom?.toLocaleLowerCase() &&
      responsableContact.genre?.toLocaleLowerCase() === responsableEquipe.identite?.genre?.toLocaleLowerCase() &&
      responsableContact.prenom?.toLocaleLowerCase() === responsableEquipe.identite?.prenom?.toLocaleLowerCase()
    );
  }

  isDepotOrPreDepot(projet: Projet): boolean {
    const etape = this.getProjectEtapeName(projet);
    return etape === EnumProjetEtape.PRE_DEPOT || etape === EnumProjetEtape.DEPOT;
  }

  canUserActionButton(projet: Projet, user: Utilisateur): boolean {
    // TODO: Ajouter les permissions liées aux autres actions au fur et a mesure sur le menu action avec des OR, pour l'instant j'ai ajouté que PROJET_DEMANDE_COMPLEMENT_WRITE
    return (
      (PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_DEMANDE_COMPLEMENT_WRITE, projet) ||
        PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_DEMANDECORRECTION_WRITE, projet)) &&
      this.isUserAffectedToOneOfProjectActivities(projet, user, EnumActivite.all())
    );
  }

  getBudgetProjet(projet: Projet, aap: Aap) {
    const structureList = projet.structures.filter(structure => structure.role !== EnumRoleStructure.MANDATAIRE && !structure.closed);

    let declaredBudgetSum = 0;
    structureList.forEach(structure => {
      if (this.getProjectEtapeName(projet) === EnumProjetEtape.PRE_DEPOT && !aap?.budgetEstime) {
        declaredBudgetSum += structure.budgetPreDepot?.montant ? Number(structure.budgetPreDepot.montant) : 0;
      } else if (this.getProjectEtapeName(projet) !== EnumProjetEtape.PRE_DEPOT) {
        declaredBudgetSum += structure.budgetDepot?.montant ? Number(structure.budgetDepot.montant) : 0;
      }
    });
    return JSON.stringify(declaredBudgetSum);
  }

  aapHasSelectedCritereEvaluation(aap: Aap, phase: EnumCriteresReferentielOptions): boolean {
    return aap?.criteresEvaluations?.length > 0 && !!aap.criteresEvaluations.find(crit => crit.phases.includes(phase));
  }

  getNotationType(aap: Aap, typeNotation: string) {
    if (aap?.notationEvaluationTypeNote && typeNotation === EnumPhaseType.EVALUATION.toString()) {
      return aap.notationEvaluationTypeNote;
    }
    if (aap?.notationAuditionTypeNote && typeNotation === EnumPhaseType.AUDITION.toString()) {
      return aap.notationAuditionTypeNote;
    }
    if (aap?.notationInstructionTypeNote && typeNotation === EnumPhaseType.INSTRUCTION.toString()) {
      return aap.notationInstructionTypeNote;
    }
  }

  getDocStructureAap(aap: Aap, structure: Structure, projet: Projet): DocumentAapModel[] {
    return aap.documentAaps?.filter((document: any) => {
      if (
        document.etapes.includes(this.getProjectEtapeName(projet)) &&
        document.typePartenaires?.includes(projet.partenaireType) &&
        document.scope === EnumScope.STRUCTURE
      ) {
        const roleMatching = document.roleStructures.filter((role: EnumRoleStructure) => {
          return structure.role === role;
        });
        const typeStructMatching = document.typeStructures.filter((type: string) => {
          return structure.typeStructure === type;
        });

        if (roleMatching.length > 0 && typeStructMatching.length > 0) {
          return document;
        }
      }
    });
  }

  getDocActioStructureAap(aap: Aap, structure: Structure, projet: Projet): DocumentAapModel[] {
    return aap.documentAaps?.filter((document: any) => {
      if (
        document.etapes.includes(this.getProjectEtapeName(projet)) &&
        document.typePartenaires?.includes(projet.partenaireType) &&
        document.scope === EnumScope.STRUCTURE_ACTIONARIAL
      ) {
        const roleMatching = document.roleStructures.filter((role: EnumRoleStructure) => {
          return structure.role === role;
        });
        const typeStructMatching = document.typeStructures.filter((type: string) => {
          return structure.typeStructure === type;
        });

        if (roleMatching.length > 0 && typeStructMatching.length > 0) {
          return document;
        }
      }
    });
  }

  isDisabledFormValid(form: FormGroup): boolean {
    const wasDisabled = form.disabled;
    form.enable();
    const formValid = form.valid;
    if (wasDisabled) {
      form.disable();
    }
    return formValid;
  }

  isDemandeRectificationExist(structure: Structure): boolean {
    return structure && structure.adresse?.demandeRectification != null;
  }

  isDemandeRectificationTreated(structure: Structure): boolean {
    return Boolean(structure && structure.adresse?.demandeRectification && structure.adresse.demandeRectification.demandeTraitee);
  }

  canUserWriteInterventionPI(user: Utilisateur, projet: Projet): boolean {
    return (
      this.canUserWriteActivity(user, projet, EnumPermissionUtilisateur.PROJET_INSTRUCTION_WRITE, EnumActivite.INSTRUCTION) &&
      this.isUserAffectedToOneOfProjectInstructionAsIntervenantPI(projet, user, EnumActivite.INSTRUCTION)
    );
  }

  isUserAffectedToOneOfProjectInstructionAsIntervenantPI(projet: Projet, user: Utilisateur, activite: EnumActivite): boolean {
    return (
      projet?.instructeurs?.find(
        instructeur =>
          instructeur.matricule === user.matricule &&
          instructeur.actif &&
          instructeur.activites.length > 0 &&
          instructeur.activites.find(a => a.nomActivite === activite && a.intervenantPI)
      ) != null
    );
  }

  canUserManageInterventionPI(user: Utilisateur, projet: Projet): boolean {
    return (
      this.canUserWriteActivity(user, projet, EnumPermissionUtilisateur.PROJET_INSTRUCTION_WRITE, EnumActivite.INSTRUCTION) &&
      this.isUserAffectedToOneOfProjectInstructionAndNotAsIntervenantPI(projet, user, EnumActivite.INSTRUCTION)
    );
  }

  isUserAffectedToOneOfProjectInstructionAndNotAsIntervenantPI(projet: Projet, user: Utilisateur, activite: EnumActivite): boolean {
    return (
      projet?.instructeurs?.find(
        instructeur =>
          instructeur.matricule === user.matricule &&
          instructeur.actif &&
          instructeur.activites.length > 0 &&
          instructeur.activites.find(a => a.nomActivite === activite && !a.intervenantPI)
      ) != null
    );
  }
}
