import { enumKeys } from "../../utils/enum-utils";

export enum EnumNotationTypeNote {
  NOTATION_FAVORABLE_DEFAVORABLE = 'NOTATION_FAVORABLE_DEFAVORABLE',
  NOTATION_ABC = 'NOTATION_ABC',
  NOTATION_1234 = 'NOTATION_1234',
  NOTATION_NOTE_SUR_20 = 'NOTATION_NOTE_SUR_20',
  NOTATION_FAVORABLE_DEFAVORABLE_REORIENTATION = 'NOTATION_FAVORABLE_DEFAVORABLE_REORIENTATION',
  NOTATION_A_RETENIR_SANS_SUITE = 'NOTATION_A_RETENIR_SANS_SUITE',
  NOTATION_FAVORABLE_DEFAVORABLE_RESERVE_AJOURNEE = 'NOTATION_FAVORABLE_DEFAVORABLE_RESERVE_AJOURNEE',
}

export namespace EnumNotationTypeNote {
  export function toString(notation: EnumNotationTypeNote): string {
switch (notation) {
      case EnumNotationTypeNote.NOTATION_FAVORABLE_DEFAVORABLE:
        return 'Favorable / Défavorable';
      case EnumNotationTypeNote.NOTATION_ABC:
        return 'ABC';
      case EnumNotationTypeNote.NOTATION_1234:
        return '1234';
      case EnumNotationTypeNote.NOTATION_NOTE_SUR_20:
        return 'Note sur 20';
      case EnumNotationTypeNote.NOTATION_FAVORABLE_DEFAVORABLE_REORIENTATION:
        return 'Favorable - Défavorable - Réorientation';
      case EnumNotationTypeNote.NOTATION_A_RETENIR_SANS_SUITE:
        return 'A retenir - Sans suite';
      case EnumNotationTypeNote.NOTATION_FAVORABLE_DEFAVORABLE_RESERVE_AJOURNEE:
        return 'Favorable - Défavorable - Réservé - Ajournement';
    }
  }

  export function all(): EnumNotationTypeNote[] {
    return enumKeys(EnumNotationTypeNote) as unknown as EnumNotationTypeNote[];
  }
}
